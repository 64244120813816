<template>
  <b-modal v-model="modalDimensions" :title=" $t('dashboard.products.dimensions') " centered content-class="card form-rounded-inputs card-ecart"
    header-class="card-header" @hidden="fnResetProductDimensions()" cancel-variant="danger" size="md" hide-header-close>
    <product-card :product="productInformation"></product-card>
    <hr>
    <div class="">
      <p>
        <span v-text="$t('general.form.dimensions')"></span>
        <i class=" fa fa-question-circle text-info ml-1" id="popover-productDimensionsModal"></i>
        <b-popover placement="top" target="popover-productDimensionsModal" triggers="hover">
          <p v-html="$t('components.product.dimesnionsMessage')"></p>
          <p class="text-center">
            <img src="@/assets/images/box.webp" class="img-contain-200 border-0" alt="">
          </p>
        </b-popover>
      </p>
      <v-observer tag="form" @submit.prevent="fnValidate()" ref="variantsDimensionsform">
        <div class="row">
          <div class="col-12 ">
            <div class="row">
              <div class="col-6 col-sm-6 ">
                <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.width') ">
                  <div class="form-group">
                    <label for="">
                      <i class="fa fa-arrows-alt-h mr-1 "></i>
                      <span v-text="$t('general.form.width')"></span>
                    </label>
                    <div class="input-group">
                      <input type="number" min="1" step="1" class="form-control" v-model=" dimensions.width "
                        :class=" { 'is-invalid':errors.length > 0 } ">
                      <div class="input-group-append">
                        <span class="input-group-text rounded-right " id="unitsWidth">
                          <strong v-text=" CONST.UNITS.LENGTH "> </strong> </span>
                      </div>
                      <div class="invalid-feedback" v-if="errors.length > 0">
                        {{ errors[0] }}
                      </div>
                    </div>
                  </div>
                </v-validation>
              </div>

              <div class="col-6 col-sm-6 ">
                <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.height') ">
                  <div class="form-group">
                    <label for="">
                      <i class="fa fa-arrows-alt-v mr-1 "></i>
                      <span v-text="$t('general.form.height')"></span>
                    </label>
                    <div class="input-group">
                      <input type="number" min="1" step="1" class="form-control" v-model=" dimensions.height  "
                        :class=" { 'is-invalid':errors.length > 0 } ">
                      <div class="input-group-append">
                        <span class="input-group-text rounded-right" id="unitsHeight">
                          <strong v-text=" CONST.UNITS.LENGTH "> </strong> </span>
                      </div>
                      <div class="invalid-feedback" v-if="errors.length > 0">
                        {{ errors[0] }}
                      </div>
                    </div>
                  </div>
                </v-validation>
              </div>

              <div class="col-6 col-sm-6 ">

                <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.length') ">
                  <div class="form-group">
                    <label for="">
                      <i class=" fa fa-ruler-vertical mr-1"></i>
                      <span v-text=" $t('general.form.length') "></span>
                    </label>
                    <div class="input-group">
                      <input type="number" min="1" step="1" class="form-control" v-model=" dimensions.length  "
                        :class=" { 'is-invalid':errors.length > 0 } ">
                      <div class="input-group-append">
                        <span class="input-group-text rounded-right" id="unitsDepth">
                          <strong v-text=" CONST.UNITS.LENGTH "> </strong> </span>
                      </div>
                      <div class="invalid-feedback" v-if="errors.length > 0">
                        {{ errors[0] }}
                      </div>
                    </div>
                  </div>
                </v-validation>
              </div>
              <div class="col-6 col-sm-6 ">
                <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.weight') ">
                  <div class="form-group">
                    <label for="">
                      <i class="fa fa-weight-hanging mr-1"></i>
                      <span v-text=" $t('general.form.weight') "></span>
                    </label>
                    <div class="input-group">
                      <input type="number" min="0.1" step="0.1" class="form-control" v-model=" dimensions.weight  "
                        :class=" { 'is-invalid':errors.length > 0 } ">
                      <div class="input-group-append">
                        <span class="input-group-text rounded-right" id="unitsWeight">
                          <strong v-text=" CONST.UNITS.WEIGHT "> </strong> </span>
                      </div>
                      <div class="invalid-feedback" v-if="errors.length > 0">
                        {{ errors[0] }}
                      </div>
                    </div>
                  </div>
                </v-validation>
              </div>

            </div>
          </div>
          <input type="submit" hidden>
        </div>
        <v-validation :rules="{'required':productInfo.variants.length > 0}" v-slot="{ errors }"
          :name=" $t('tables.selectVariants') " v-show="productInfo.variants.length > 0">
          <div class="">
            <table class="table table-striped table-hover ">
              <thead>
                <tr>
                  <th>
                    <input type="checkbox" id="selectAllVAriants" :checked="selectedAll"
                      @click=" fnSelectAllVariants() ">
                  </th>
                  <th class="col">
                    <label :for=" 'selectAllVAriants' " class=" m-0" v-text=" $t('tables.selectVariants') "> </label>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for=" (variant,index) in productInfo.variants " :key="index">
                  <td>
                    <input type="checkbox" class="mt-1" v-model="variantList" :value="variant.id"
                      :id=" index + 'variant' ">
                  </td>
                  <td>
                    <label :for=" index + 'variant'" class=" m-0 w-100">
                      <p class="m-0">
                        <span v-text=" $t('tables.name') + ': ' "></span> <strong v-text=" variant.name "></strong>
                      </p>
                      <p class="m-0">
                        <span v-html=" $t('tables.current') +': '  + fnFormatDimenisons( variant.dimensions )  ">
                        </span>
                      </p>
                    </label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="bg-danger px-2" v-if="errors.length > 0">
            {{ errors[0] }}
          </div>
        </v-validation>

      </v-observer>
    </div>

    <template v-slot:modal-footer>
      <div class="d-flex w-100">
        <div class="ml-auto">
          <b-button variant="none" class=" mr-2" @click=" fnResetProductDimensions() "
            v-text=" $t('general.button.close') ">
          </b-button>
          <b-button variant="success" class="" @click=" fnValidate() " :disabled="sendingData">
            <span v-text=" $t('general.button.update') " v-if=" !sendingData "></span>
            <i class="fa fa-spinner fa-spin " v-else></i>
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>

</template>

<script>
  import {
    mapMutations,
    mapState,
  } from 'vuex'

  export default {
    name: "ProductDimensionsModal",
    props: {
      showModalDimensions: {
        type: Boolean,
        default: false,
      },
      productInfo: {
        type: Object,
        required: true,
      },
    },
    watch: {
      showModalDimensions() {
        this.modalDimensions = true;
      },
      productInfo() {
        if (this.productInfo != null && this.productInfo.dimensions) {
          if (this.productInfo.variants.length > 0) {
            let tempVariants = [];
            for (let variant of this.productInfo.variants) {
              if (variant.name != 'Default Title') {
                tempVariants.push(variant);
              }

            }
            this.productInfo.variants = tempVariants;
          }
          if (this.productInfo.variants.length == 0) {
            this.dimensions.width = this.productInfo.dimensions.width || null;
            this.dimensions.height = this.productInfo.dimensions.height || null;
            this.dimensions.length = this.productInfo.dimensions.length || null;
            this.dimensions.weight = this.productInfo.dimensions.weight || null;
          }
        } else {}

      }
    },
    computed: {
      selectedAll() {
        if (this.productInfo.variants.length > 0) {
          return this.variantList.length == this.productInfo.variants.length;
        } else {
          return false;
        }
      },
      ...mapState('product', ['productInformation']),
    },
    data() {
      return ({
        variantList: [],
        sendingData: false,
        modalDimensions: false,
        dimensions: {
          width: null,
          height: null,
          length: null,
          weight: null,
        },
      })
    },

    methods: {
      ...mapMutations('product', ['updateProductVariantsDimensions', 'updateProductDimensions']),
      async fnValidate() {
        if (await this.$refs['variantsDimensionsform'].validate()) {
          this.fnUpdateDimensions();
        }
      },
      async fnUpdateDimensions() {
        this.sendingData = true;
        await axios.put(`products/${this.productInfo.id}/variants`, {
          dimensions: this.dimensions,
          ids: this.variantList
        }).then((response) => {
          this.updateProductVariantsDimensions({
            dimensions: this.dimensions,
            variantList: this.variantList
          })
          this.$toasted.global.infoMessage(this.$t('toasted.info.productDimensionsUpdated'));
        }).catch(error => {});
        this.fnResetProductDimensions();
        this.sendingData = false;
      },

      fnResetProductDimensions() {
        this.modalDimensions = false;
        this.dimensions = {
          width: null,
          height: null,
          length: null,
          weight: null,
        };
        this.variantList = [];
      },

      fnSelectAllVariants() {
        if (this.variantList.length < this.productInfo.variants.length) {
          this.variantList = [];
          for (let index in this.productInfo.variants) {
            this.variantList.push(this.productInfo.variants[index].id);
          }
        } else {
          this.variantList = [];
        }
      },
    }
  }
</script>

<style>

</style>